<template>
  <div class="relative w-full">
    <textarea
      :id="settingId ?? id"
      v-model="modelValue"
      rows="4"
      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 !font-[Inter] !mb-0"
      :placeholder="placeholder || 'Write your thoughts here...'"
      :readonly="readonly"
      :autofocus="autofocus"
      :required="required"
      :name="name"
      :disabled="disabled"
      :maxlength="maxLength"
    />
    <slot />
    <div v-if="maxLength" class="absolute bottom-2 right-2 flex items-center">
      <div class="relative size-8">
        <svg viewBox="0 0 20 20">
          <circle
            :class="remainingChars > 20 ? 'text-blue-700' : 'text-red-500'"
            stroke-width="1.5"
            stroke="currentColor"
            fill="transparent"
            r="8"
            cx="10"
            cy="10"
          />
          <circle
            stroke-width="1.5"
            class="text-gray-400"
            :stroke-dasharray="50"
            :stroke-dashoffset="50 - (remainingChars / maxLength) * 50"
            stroke-linecap="round"
            stroke="currentColor"
            fill="transparent"
            r="8"
            cx="10"
            cy="10"
          />
        </svg>
        <span
          class="absolute inset-0 flex items-center justify-center text-[8px]"
        >
          {{ remainingChars }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { useSettingControl } from "@/components/Setting/context";

const props = defineProps<{
  readonly?: boolean;
  id?: string;
  autofocus?: boolean;
  required?: boolean;
  placeholder?: string;
  maxLength?: number;
  name?: string;
  disabled?: boolean;
}>();

const modelValue = defineModel<string>("modelValue");

const setting = useSettingControl();
const settingId = setting?.register();

const remainingChars = computed(() => {
  return (props.maxLength ?? 0) - (modelValue.value?.length ?? 0);
});
</script>
